import { vp_bp_factor } from '../enums';
import useViewportBreakpoint from './use-viewport-breakpoint';

function useResponsiveTypography(): {
  getFontSize: (number, number, number) => string,
} {
  const { isSm, isXxl } = useViewportBreakpoint();

  const getFontSize = (
    minSizeFactor: number = 0.75,
    maxSizeFactor: number = minSizeFactor,
    systemBaseSizeFactor: number = 1
  ) => {
    if (isXxl) {
      return `calc(${maxSizeFactor} * ${systemBaseSizeFactor}rem)`;
    }

    if (isSm) {
      const minActualSize = `${minSizeFactor} * ${systemBaseSizeFactor}rem`;
      const sizeFactorRange = `${maxSizeFactor} - ${minSizeFactor}`;
      const viewportRange = `(100vw - (${vp_bp_factor.sm} * ${systemBaseSizeFactor}rem)) / (${vp_bp_factor.xxl} - ${vp_bp_factor.sm})`;

      return `calc( (${minActualSize}) + (${sizeFactorRange}) * (${viewportRange}) )`;
    }

    return `calc(${minSizeFactor} * ${systemBaseSizeFactor}rem)`;
  };

  return { getFontSize };
}

export default useResponsiveTypography;
