import { Pane, Paragraph, majorScale } from 'evergreen-ui';
import { ReactComponent as EmptyStateIcon } from '../../assets/icons/empty-state.svg';
import {useEffect} from "react";
import mixPanel from "../../analytics/mix-panel";
import events from "../../analytics/mix-panel/events";

function CustomEmptyState({
  message = "We couldn't find your plugg in this area",
}) {

  useEffect(() => {
    mixPanel.track(events.EMPTY_SEARCH_RESULTS)
  }, [])

  return (
    <Pane
      width="80%"
      maxWidth={240}
      marginX="auto"
      marginTop={majorScale(5)}
      marginBottom={majorScale(5)}
      textAlign="center"
    >
      <EmptyStateIcon />
      <Paragraph
        marginTop="0.75em"
        fontSize="1.125em"
        color="var(--grey-medium)"
      >
        {message}
      </Paragraph>
    </Pane>
  );
}

export default CustomEmptyState;
