import { Text, Pane, Dialog, Heading, Paragraph } from 'evergreen-ui';
import useViewportBreakpoint from '../../../hooks/use-viewport-breakpoint';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import useResponsiveTypography from '../../../hooks/use-responsive-typography';
import PluggRatingIcon from '../../../components/plugg-rating-icon/plugg-rating-icon';
import { ReactComponent as PluggAvatarIcon } from '../../../assets/icons/plugg-avatar.svg';
import {useEffect} from "react";
import mixPanel from "../../../analytics/mix-panel";
import events from "../../../analytics/mix-panel/events";

function PluggDetailsDialog({ plugg, onCloseCallback }) {
  const viewportBreakpoint = useViewportBreakpoint();
  const responsiveTypography = useResponsiveTypography();

    useEffect(() => {
        mixPanel.track(events.VIEWED_ARTISAN_DETAILS, {
            artisanName: plugg.name
        })
    }, []);

  return (
    <Dialog
      isShown
      hasFooter={false}
      hasHeader={false}
      preventBodyScrolling
      onCloseComplete={onCloseCallback}
      containerProps={{
        backgroundColor: 'var(--grey-ultra-light)',
        ...(viewportBreakpoint.isSm
          ? {}
          : {
              margin: 0,
              maxWidth: '100%',
              height: '100vh',
              maxHeight: '100%',
              borderRadius: 0,
            }),
      }}
      contentContainerProps={{ padding: 0, borderRadius: 'inherit' }}
    >
      {({ close }) => {
        return (
          <>
            <Pane
              is="header"
              padding="3em"
              textAlign="center"
              backgroundColor="#fff"
            >
              <CloseIcon
                style={{
                  position: 'absolute',
                  top: 20,
                  right: 20,
                  cursor: 'pointer',
                }}
                onClick={close}
              />

              <Pane
                width="100px"
                height="100px"
                marginX="auto"
                marginBottom="1em"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                backgroundColor="var(--grey-ultra-light)"
              >
                <PluggAvatarIcon width="2.5em" height="2.5em" fill="#000" />
              </Pane>

              <Heading
                is="h2"
                marginBottom="0.25em"
                fontSize={responsiveTypography.getFontSize(1.75, 2.5)}
              >
                {plugg.name}
              </Heading>

              <Paragraph>
                <PluggRatingIcon
                  width="10"
                  height="10"
                  verified={plugg.services[0].verified.toString()}
                />
                <Text
                  opacity="0.6"
                  marginLeft="0.5em"
                  fontSize={responsiveTypography.getFontSize(1, 1.25)}
                >
                  {plugg.services[0].verified ? 'Verified' : 'Unverified'}{' '}
                  <b>{plugg.services[0].serviceName}</b> in{' '}
                  <b>
                    {[plugg.location.area, plugg.location.state].join(', ')}
                  </b>
                </Text>
              </Paragraph>
            </Pane>

            <Pane
              is="section"
              padding="3em"
              backgroundColor="var(--grey-ultra-light)"
            >
              <Heading is="h3" marginBottom="0.75em" fontWeight="700">
                Services
              </Heading>

              {plugg.services.map((service) => (
                <Paragraph
                  marginRight="0.5em"
                  paddingX="0.75em"
                  paddingY="0.25em"
                  display="inline-block"
                  color="#4C259F"
                  borderRadius="0.5em"
                  backgroundColor="#FFF"
                  boxShadow="1px 3px 4px rgba(0, 0, 0, 0.05)"
                  fontSize={responsiveTypography.getFontSize(1, 1.25)}
                >
                  {service.serviceName}
                </Paragraph>
              ))}

              <Heading
                is="h3"
                marginTop="3em"
                marginBottom="0.75em"
                fontWeight="700"
              >
                Contact
              </Heading>

              <Paragraph fontSize={responsiveTypography.getFontSize(1, 1.25)}>
                {plugg.phoneNumber}
              </Paragraph>
            </Pane>
          </>
        );
      }}
    </Dialog>
  );
}

export default PluggDetailsDialog;
