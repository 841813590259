import { Paragraph, majorScale } from 'evergreen-ui';
import { getErrorMessage } from '../../helpers';
import {useEffect} from "react";
import mixPanel from "../../analytics/mix-panel";
import events from "../../analytics/mix-panel/events";

function ErrorState({ error }) {

  useEffect(() => {
    mixPanel.track(events.EMPTY_SEARCH_RESULTS)
  }, [])

  return (
    <Paragraph
      width="80%"
      maxWidth={240}
      marginX="auto"
      marginTop={majorScale(5)}
      marginBottom={majorScale(5)}
      textAlign="center"
      fontSize="1.125em"
      fontStyle="italic"
      color="#A73636"
    >
      {getErrorMessage(error)}
    </Paragraph>
  );
}

export default ErrorState;
