const VIEWED_HOMEPAGE = 'Viewed Homepage';
const SEARCHED_ARTISAN = 'Searched for an Artisan';
const LANDED_ON_RESULT = 'Landed on Results Page';
const GOT_SEARCH_RESULTS = 'Got Search Results';
const EMPTY_SEARCH_RESULTS = 'Empty Search Results';
const ERROR_SEARCH_RESULTS = 'Empty Search Results';
const VIEWED_ARTISAN_DETAILS = 'Viewed Artisan Details';
const CLICKED_SUGGESTION_FORM = 'Clicked Suggestion Form';
const ERROR_OCCURRED = 'Error Occurred';

export default {
    VIEWED_HOMEPAGE,
    SEARCHED_ARTISAN,
    LANDED_ON_RESULT,
    GOT_SEARCH_RESULTS,
    EMPTY_SEARCH_RESULTS,
    ERROR_SEARCH_RESULTS,
    VIEWED_ARTISAN_DETAILS,
    CLICKED_SUGGESTION_FORM,
    ERROR_OCCURRED
}
