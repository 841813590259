import Mixpanel from "../analytics/mix-panel";
import events from "../analytics/mix-panel/events";

export function stringNormalizer(string: string): string {
  return string.trim().replace(/\s{2,}/g, ' ');
}

export function getErrorMessage(error) {
  if (error?.response) {
    // The request was made
    // the server responded with a status code that falls out of the range of 2xx
    Mixpanel.track("Get Error Response", error.response.data.message || error.response.data.error)
    return error.response.data.message || error.response.data.error;
  }

  if (error?.message) {
    // Something happened in setting up the request that triggered an Error
    Mixpanel.track("Get Error Message", error.message)
    return error.message;
  }

  if (error?.request) {
    // The request was made but no response was received
    Mixpanel.track("Get Error Request", error.request)
    return 'Something went wrong';
  }
}

export const getErrorResponse = (error) => {
  if (error !== null && error !== "null" && error !== "undefined" && error !== undefined) {
    if (error.response) {
      Mixpanel.track(events.ERROR_OCCURRED, {data: error.response.data, message: error.message ?? "N/A"})
    }
  }
}
