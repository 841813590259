import * as React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import TextTransition, {presets} from 'react-text-transition';
import { Text, Pane, Heading, Paragraph } from 'evergreen-ui';
import PluggSearchForm from '../../components/plugg-search-form/plugg-search-form';

import usePluggServices from '../../hooks/use-plugg-services';
import Mixpanel from '../../analytics/mix-panel';
import ipLookup from "../../analytics/mix-panel/ipLookup";
import events from "../../analytics/mix-panel/events";
import useResponsiveTypography from '../../hooks/use-responsive-typography';
import {useEffect} from "react";
import {getErrorResponse} from "../../helpers";

const StyledTextTransition = styled(TextTransition)`
  div > div {
    font-family: 'Volte' !important;
    color: #f46b30;
  }
`;

function AnimatedServiceName() {
  const pluggServices = usePluggServices();

  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000);
    return () => clearTimeout(intervalId);
  }, []);

  if (pluggServices.isLoading || pluggServices.isError) {
    return (
      <Text
        fontSize="inherit"
        fontFamily="Volte"
        fontWeight="inherit"
        color="#f46b30"
      >
        artisan
      </Text>
    );
  }

  const serviceNames = [
    'artisan',
    ...pluggServices.data.data.map((service) => service.name),
  ];

  return (
    <Text
      display="inline-block"
      marginX="8px"
      fontSize="inherit"
      fontFamily="Volte"
      fontWeight="inherit"
    >
      {serviceNames[index % serviceNames.length].split(' ').map((n, i) => {
        return (
          <>
            <StyledTextTransition springConfig={presets.stiff} key={i} inline overflow text={n} />{' '}
          </>
        );
      })}
    </Text>
  );
}

function Home() {
  const navigate = useNavigate();
  const responsiveTypography = useResponsiveTypography();

  useEffect(() =>{
      ipLookup().then(res => {
          Mixpanel.track(events.VIEWED_HOMEPAGE, res)
      }).catch(error =>{
          getErrorResponse(error)
      })
  },[])



  return (
    <Pane is="main" flexGrow={1} display="flex" flexDirection="column">
      <Pane
        is="section"
        paddingY={responsiveTypography.getFontSize(0, 3)}
        fontSize="1rem"
        textAlign="center"
        className="container-lg"
      >
        <Heading
          is="h1"
          fontWeight={600}
          fontSize={responsiveTypography.getFontSize(2.75, 4.75)}
        >
          Find the right
          <br />
          <AnimatedServiceName />
          <br />
          for that project
        </Heading>

        <Paragraph
          marginTop="2em"
          fontSize={responsiveTypography.getFontSize(1.25, 1.25)}
        >
          Finally, the directory of the top-rated local artisans that
          you&apos;ve been looking for!
        </Paragraph>
      </Pane>

      <Pane
        is="section"
        paddingY="2em"
        flexGrow={1}
        fontSize="1rem"
        className="container-md"
      >
        <PluggSearchForm
          isLoading={false}
          onReset={() => {}}
          onSubmit={(values) => {
            navigate(
              `/search?serviceName=${values.service.label}&serviceCode=${values.service.value}&stateCode=${values.state.value}&stateName=${values.state.label}&sourcePage=HomePage`
            );
          }}
        />
      </Pane>

      <Pane
        is="section"
        paddingY="3em"
        fontSize="1rem"
        textAlign="center"
        className="container-lg"
      >
        <Paragraph
          marginBottom="1.5em"
          color="var(--grey-medium)"
          fontSize={responsiveTypography.getFontSize(1.75, 2.5)}
        >
          Over 100+ industry-vetted service professionals*
        </Paragraph>

        <Paragraph fontSize="1em">
          *Service professionals are rated by clients and customers that
          they&apos;ve served.
        </Paragraph>
      </Pane>
    </Pane>
  );
}

export default Home;
