import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from '../../pages/home/home';
import Search from '../../pages/search/search';
import NotFound from '../../pages/not-found/not-found';
import PageLayout from '../page-layout/page-layout';

function App() {
  return (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route index element={<Home />} />
        <Route path="search" element={<Search />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
