// @noflow

import axios from 'axios';

const CancelToken = axios.CancelToken;

export let source;

const httpClient = axios.create({
  baseURL: 'https://api.usemyplugg.com/api/v1',
});

httpClient.interceptors.request.use((config) => {
  source = CancelToken.source();
  config.cancelToken = source.token;
  config.headers = { 'client-key': 'a77abe617d55f568ccfde37111268614' };
  return config;
});

export default httpClient;
