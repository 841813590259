import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider, defaultTheme } from 'evergreen-ui';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './components/app/app';
// import reportWebVitals from './reportWebVitals';

import './assets/css/index.css';
import './assets/css/animate.css';
import './assets/css/bootstrap-utils.css';

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    default: '#000',
  },
  fontFamilies: {
    ...defaultTheme.fontFamilies,
    ui: `'SF Pro Display', 'SF UI Text', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif`,
  },
  components: {
    ...defaultTheme.components,
    Heading: {
      ...defaultTheme.components.Button,
      baseStyle: { ...defaultTheme.components.Button.baseStyle, color: '#000' },
    },
    Button: {
      ...defaultTheme.components.Button,
      baseStyle: { ...defaultTheme.components.Button.baseStyle },
      appearances: {
        ...defaultTheme.components.Button.appearances,
        'plugg-primary': {
          color: '#fff',
          backgroundColor: 'var(--primary)',
          _hover: {
            backgroundColor: '#e55112',
          },
          _active: {
            backgroundColor: '#e55112',
          },
          _disabled: {
            backgroundColor: 'var(--secondary)',
          },
          _focus: {
            boxShadow: '0 0 0 2px var(--secondary)',
          },
        },
      },
    },
  },
};

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider value={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
