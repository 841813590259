import { useQuery } from 'react-query';
import httpClient from '../libs/http-client';

function usePluggLocations() {
  const fetchLocations = async () => {
    const response = await httpClient.get('/artisan/states');
    return response.data;
  };

  return useQuery(['plugg_states'], fetchLocations, {
    refetchOnWindowFocus: false,
  });
}

export default usePluggLocations;
