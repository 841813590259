import styled from '@emotion/styled';
import { Link, Outlet } from 'react-router-dom';
import { Pane, majorScale } from 'evergreen-ui';
import { ReactComponent as BrandLogo } from '../../assets/images/brand-logo.svg';

const StyledBrandLogo = styled(BrandLogo)`
  path[fill-opacity] {
    transition: fill-opacity 300ms ease-out;
  }

  &:hover path[fill-opacity] {
    fill-opacity: 1;
  }
`;

function PageLayout() {
  return (
    <Pane display="flex" flexDirection="column" minHeight="100vh">
      <Pane
        is="header"
        paddingY={majorScale(4)}
        className="container-fluid"
        textAlign="center"
      >
        <Link to="/">
          <StyledBrandLogo />
        </Link>
      </Pane>

      <Outlet />
    </Pane>
  );
}

export default PageLayout;
