import Select from 'react-select';
import styled from '@emotion/styled';
import {
  Pane,
  Label,
  Paragraph,
  ErrorIcon,
  minorScale,
  majorScale,
} from 'evergreen-ui';

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 'unset',
      width: state.selectProps.width || '100%',
      height: state.selectProps.height || 64,
      color: state.selectProps.color || '#000',
      fontSize: state.selectProps.fontSize || '1.25em',
      fontWeight: 600,
      borderRadius: state.selectProps.borderRadius || 10,
      backgroundColor: state.selectProps.backgroundColor || '#fff',
      opacity: state.selectProps['aria-disabled'] ? 0.3 : 1,
      borderColor: state.selectProps['aria-invalid']
        ? '#D14343 !important'
        : state.menuIsOpen || state.isFocused
        ? 'var(--grey-light) !important'
        : 'var(--grey-light) !important',
      boxShadow:
        state.menuIsOpen || state.isFocused
          ? '0 0 0 2px var(--grey-ultra-light) !important'
          : 'none !important',
      transition: 'all 300ms ease-out',
    };
  },

  valueContainer: (provided, state) => {
    return {
      ...provided,
      paddingLeft: 20,
      paddingRight: 20,
      height: '100%',
    };
  },

  placeholder: (provided, state) => {
    return {
      ...provided,
      color: 'inherit',
    };
  },

  singleValue: (provided, state) => {
    return {
      ...provided,
      color: 'inherit',
    };
  },

  indicatorsContainer: (provided, state) => {
    return {
      ...provided,
      height: '100%',
    };
  },

  indicatorSeparator: (provided, state) => {
    return {
      ...provided,
      display: 'none',
    };
  },

  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: 'inherit !important',
    };
  },

  menu: (provided, state) => {
    return {
      ...provided,
      backgroundColor: '#000',
      borderRadius: 10,
      paddingLeft: 16,
      paddingRight: 16,
    };
  },

  menuList: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'inherit',
      maxHeight: 298,
    };
  },

  option: (provided, state) => {
    return {
      ...provided,
      color: state.isFocused ? 'var(--primary)' : '#fff',
      fontSize: '1.25em',
      cursor: 'pointer',
      padding: 4,
      marginTop: 4,
      marginBottom: 4,
      lineHeight: 1.2,
      backgroundColor: 'inherit !important',
      transition: 'all 300ms ease-out',
    };
  },
};

function getValueWithLabel(value, options) {
  if (value && value.hasOwnProperty('label')) {
    return value;
  }
  return options.find((option) => option.value === value);
}

const StyledSelect = styled(Select)`
  .form-select-box__dropdown-indicator svg {
    width: 16px;
  }

  .form-select-box__menu-list {
    scrollbar-width: thin;
    scrollbar-color: var(--grey-ultra-light) var(--grey-ultra-light);

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: '#000';
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--grey-ultra-light);
    }
  }
`;

function FormSelectBox({
  label,
  name,
  value,
  options = [],
  placeholder,
  isLoading,
  isInvalid,
  disabled,
  validationMessage,
  onChange,
  ...rest
}) {
  return (
    <Pane>
      {label && (
        <Pane marginBottom={majorScale(1)} display="flex">
          <Label htmlFor={name}>{label}</Label>
        </Pane>
      )}

      <StyledSelect
        isSearchable={false}
        styles={customStyles}
        inputId={name}
        aria-label={name} /* allows component to be testable */
        name={name}
        value={value && getValueWithLabel(value, options)}
        options={options}
        isLoading={isLoading}
        classNamePrefix="form-select-box"
        placeholder={placeholder}
        aria-invalid={isInvalid}
        aria-disabled={disabled}
        onChange={onChange}
        {...rest}
        {...(disabled && { menuIsOpen: !disabled })}
        // menuIsOpen
      />

      {isInvalid && validationMessage && (
        <Pane
          marginTop={majorScale(1)}
          display="flex"
          columnGap={minorScale(2)}
          color="#D14343"
        >
          <ErrorIcon size={14} />
          <Paragraph
            letterSpacing={0}
            color="#D14343"
            lineHeight="18px"
            fontSize="12px"
            fontWeight={400}
            marginBottom={0}
          >
            {validationMessage}
          </Paragraph>
        </Pane>
      )}
    </Pane>
  );
}

export default FormSelectBox;
