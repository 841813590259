import axios from 'axios';
import {IP_INFO_TOKEN} from "./config";

export default async () => {
    try {
        const ipInfoRes = await axios.get(`https://ipinfo.io/json?token=${IP_INFO_TOKEN}`);

        return {
            ip: ipInfoRes.ip,
            type: "ipInfoRes",
            env: process.env.NODE_ENV,
            tkn: process.env.REACT_APP_MIXPANEL_TOKEN,
            data: ipInfoRes.data
        }
    } catch (ipInfoReqError) {
        try {
            const ipApiRes = await axios.get("http://ip-api.com/json/");

            return {
                ip: ipApiRes.query,
                type: "ipApiRes",
                env: process.env.NODE_ENV,
                tkn: process.env.REACT_APP_MIXPANEL_TOKEN,
                data: ipApiRes.data
            }
        } catch (ipApiReqError) {
            throw new Error('Both requests failed');
        }
    }
}
