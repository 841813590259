import * as React from 'react';
import { Spinner, majorScale } from 'evergreen-ui';

function LoadingState() {
  return (
    <Spinner
      size={majorScale(6)}
      marginX="auto"
      marginTop={majorScale(5)}
      marginBottom={majorScale(5)}
    />
  );
}

export default LoadingState;
