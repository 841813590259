import { useQuery } from 'react-query';
import httpClient from '../libs/http-client';

function usePluggServices() {
  const fetchServices = async () => {
    const response = await httpClient.get('/artisan/services');
    return response.data;
  };

  return useQuery(['plugg_services'], fetchServices, {
    refetchOnWindowFocus: false,
  });
}

export default usePluggServices;
