import * as React from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Text, Pane, Heading, Paragraph } from 'evergreen-ui';
import PluggSearchResult from './components/plugg-search-result';
import PluggSearchForm from '../../components/plugg-search-form/plugg-search-form';
import CustomEmptyState from '../../components/custom-empty-state/custom-empty-state';
import CustomErrorState from '../../components/custom-error-state/custom-error-state';
import CustomLoadingState from '../../components/custom-loading-state/custom-loading-state';
import httpClient from '../../libs/http-client';
import useResponsiveTypography from '../../hooks/use-responsive-typography';
import Mixpanel from "../../analytics/mix-panel";
import events from "../../analytics/mix-panel/events";
import {getErrorResponse} from "../../helpers";

const isSearchQueryAvailable = (
  serviceCode: string,
  serviceName: string,
  stateCode: string,
  stateName: string,
): boolean => {
  return !!serviceCode && !!serviceName && !!stateCode && !!stateName;
};

function Search() {
  const responsiveTypography = useResponsiveTypography();
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = React.useState(0);

  const stateCode = searchParams.get('stateCode');
  const serviceCode = searchParams.get('serviceCode');
  const stateName = searchParams.get('stateName');
  const serviceName = searchParams.get('serviceName');
  const sourcePage = searchParams.get('sourcePage');

  const [animationClass, setAnimationClass] = React.useState('');

  React.useEffect(() => {
    if (isSearchQueryAvailable(serviceCode, serviceName, stateCode, stateName)) {
      setAnimationClass('animate__animated animate__fast animate__slideInUp');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchQuery = useQuery(
    ['search', page, serviceCode, stateCode],
    async () => {
      Mixpanel.track(events.SEARCHED_ARTISAN, {
        sourcePage: sourcePage,
        serviceCategory: serviceName,
        serviceLocation: stateName
      })
    try {
      const response = await httpClient.get('/artisan/search', {
        params: {
          page,
          size: 10,
          serviceCode,
          stateCode,
        },
      });

      return response.data;}catch(error) {
      getErrorResponse(error)
    }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: isSearchQueryAvailable(serviceCode, serviceName, stateCode, stateName),
      select: ({ message, data }) => {
        const recordsOrderedByQueriedService = data.records.map((record) => {
          const queriedService = record.services.filter(
            (service) => service.serviceCode === serviceCode
          );

          const otherServices = record.services.filter(
            (service) => service.serviceCode !== serviceCode
          );

          return { ...record, services: [...queriedService, ...otherServices] };
        });

        return {
          message,
          data: { ...data, records: recordsOrderedByQueriedService },
        };
      },
    }
  );

  const renderView = () => {
    if (searchQuery.isIdle) {
      return null;
    }

    if (searchQuery.isLoading) {
      return <CustomLoadingState />;
    }

    if (searchQuery.isError) {
      return <CustomErrorState error={searchQuery.error} />;
    }

    if (searchQuery.data?.data.totalRecords === 0) {
      return <CustomEmptyState />;
    }

    return <PluggSearchResult pluggs={searchQuery.data?.data.records} />;
  };

  const trackSuggestionClicked = () => {
    Mixpanel.track(events.CLICKED_SUGGESTION_FORM)
  }

  const handleSearchAction = ({serviceCode, serviceName, stateCode, stateName, sourcePage}) => {
    setSearchParams({serviceCode, serviceName, stateCode, stateName, sourcePage})
  }

  const totalPages = searchQuery.data?.data.totalPages || 0;
  const totalRecords = searchQuery.data?.data.totalRecords || 0;

  return (
    <Pane is="main">
      <Pane
        is="section"
        paddingY="2em"
        fontSize="1rem"
        position="relative"
        zIndex={1}
        className={`container-md ${animationClass}`}
      >
        <PluggSearchForm
          isLoading={searchQuery.isLoading}
          initialformValues={
            isSearchQueryAvailable(serviceCode, serviceName, stateCode, stateName) && {
              service: {label: serviceName, value: serviceCode},
              state: {label: stateName, value: stateCode}
            }
          }
          onSubmit={(values) => {
            handleSearchAction({
              serviceCode: values.service.value,
              serviceName: values.service.label,
              stateCode: values.state.value,
              stateName: values.state.label,
              sourcePage: 'Results page'
            })
          }}
        />
      </Pane>

      <Pane
        is="section"
        paddingY="2em"
        paddingBottom="7em"
        fontSize="1rem"
        className="container-xl animate__animated animate__slower animate__fadeIn"
      >
        <Heading
          is="h1"
          paddingBottom="0.25em"
          marginBottom="1.75em"
          borderBottom="1px solid var(--grey-medium)"
          fontSize={responsiveTypography.getFontSize(1, 1.25)}
        >
          You have{' '}
          <Text fontSize="inherit" fontWeight={700}>
            {totalRecords}
          </Text>{' '}
          result{totalRecords > 1 && 's'}
        </Heading>

        {renderView()}
      </Pane>

      <Pane
        is="footer"
        paddingY="2em"
        fontSize="1rem"
        textAlign="center"
        className="container-fluid"
        position="fixed"
        bottom={0}
        backgroundColor="#fff"
      >
        <Paragraph fontSize="1em">
          Can&apos;t find what you are looking for?{' '}
          <a
            href="https://forms.gle/gFQdYkmmG8x4JjbJ8"
            style={{ fontSize: 'inherit', fontWeight: '700' }}
            onClick={trackSuggestionClicked}
          >
            Make a suggestion
          </a>
        </Paragraph>
      </Pane>
    </Pane>
  );
}

export default Search;
