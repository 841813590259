import * as React from 'react';
import * as yup from 'yup';
import {Field, Formik} from 'formik';
import {Pane, Button} from 'evergreen-ui';
import FormSelectBox from '../form-select-box/form-select-box';
import usePluggServices from '../../hooks/use-plugg-services';
import usePluggLocations from '../../hooks/use-plugg-locations';

import useViewportBreakpoint from '../../hooks/use-viewport-breakpoint';

const validationSchema = yup.object().shape({
    service: yup.object().nullable().required('Service is required'),
    state: yup.object().nullable().required('Location is required')
});

const defaultFormValues = {service: {}, state: {}, sourcePage: ''};
const initialErrors = {service: true, state: true, sourcePage: true};

function PluggSearchForm({
                             isLoading,
                             initialformValues,
                             onReset = () => {
                             },
                             onSubmit = () => {
                             },
                         }) {
    const pluggServices = usePluggServices();
    const pluggLocations = usePluggLocations();
    const viewportBreakpoint = useViewportBreakpoint();

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialformValues || defaultFormValues}
            initialErrors={initialformValues ? {} : initialErrors}
            onSubmit={onSubmit}
        >
            {({values, touched, errors, isValid, handleSubmit, status}) => {
                return (
                    <form data-testid="plugg-search-form" onSubmit={handleSubmit}>
                        <Pane
                            display="flex"
                            flexDirection={viewportBreakpoint.isSm ? 'row' : 'column'}
                            rowGap="0.75em"
                            columnGap="0.5em"
                        >
                            <Field name="service">
                                {({field, form, meta}) => (
                                    <Pane flex={viewportBreakpoint.isSm ? '1 0 270px' : 'auto'}>
                                        <FormSelectBox
                                            name={field.name}
                                            value={field.value}
                                            options={pluggServices?.data?.data?.map((service) => ({
                                                label: service.name,
                                                value: service.code,
                                            }))}
                                            placeholder="Service Category"
                                            isLoading={pluggServices.isLoading}
                                            isInvalid={meta.touched && !!meta.error}
                                            onChange={(selected) => {
                                                form.setFieldValue(field.name, selected);
                                            }}
                                        />
                                    </Pane>
                                )}
                            </Field>

                            <Field name="state">
                                {({field, form, meta}) => (
                                    <Pane flex={viewportBreakpoint.isSm ? '1 0 180px' : 'auto'}>
                                        <FormSelectBox
                                            name={field.name}
                                            value={field.value}
                                            disabled={!!errors.service}
                                            options={pluggLocations?.data?.data?.map((location) => ({
                                                label: location.name.replace(/\sstate/i, ''),
                                                value: location.code,
                                            }))}
                                            placeholder="Location"
                                            isLoading={pluggLocations.isLoading}
                                            isInvalid={meta.touched && !!meta.error}
                                            onChange={(selected) => {
                                                form.setFieldValue(field.name, selected);
                                            }}
                                        />
                                    </Pane>
                                )}
                            </Field>

                            <Button
                                type="submit"
                                appearance="plugg-primary"
                                disabled={!isValid}
                                minHeight="64px"
                                height="auto"
                                flexGrow={1}
                                borderRadius={10}
                                isLoading={isLoading}
                                style={{fontWeight: 600, fontSize: '1.25em'}}
                            >
                                Search
                            </Button>
                        </Pane>
                    </form>
                );
            }}
        </Formik>
    );
}

export default PluggSearchForm;
