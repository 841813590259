import * as React from 'react';
import {
  Text,
  Pane,
  Tooltip,
  ListItem,
  Position,
  UnorderedList,
} from 'evergreen-ui';
import PluggDetailsDialog from './plugg-details-dialog';
import PluggRatingIcon from '../../../components/plugg-rating-icon/plugg-rating-icon';
import { ReactComponent as PluggAvatarIcon } from '../../../assets/icons/plugg-avatar.svg';
import useResponsiveTypography from '../../../hooks/use-responsive-typography';
import {useEffect} from "react";
import mixPanel from "../../../analytics/mix-panel";
import events from "../../../analytics/mix-panel/events";

const reducer = (state, action) => {
  switch (action.type) {
    case 'view_plugg_details':
      return { currentPlugg: action.payload, currentAction: action.type };

    case 'close_plugg_details':
      return { currentPlugg: null, currentAction: null };

    default:
      throw new Error('Invalid action for plugg item');
  }
};

function PluggSearchResult({ pluggs = [] }) {
  const [state, dispatch] = React.useReducer(reducer, {
    currentPlugg: null,
    currentAction: null,
  });

  useEffect(() => {
    mixPanel.track(events.GOT_SEARCH_RESULTS, {
      results: pluggs.length
    })
  }, []);

  return (
    <>
      <UnorderedList marginLeft={0}>
        {pluggs.map((plugg, i) => {
          return (
            <ListItem key={i} marginBottom="0.75em">
              <PluggItem
                plugg={plugg}
                onClick={() => {
                  dispatch({ type: 'view_plugg_details', payload: plugg });
                }}
              />
            </ListItem>
          );
        })}
      </UnorderedList>

      {state.currentAction === 'view_plugg_details' && (
        <PluggDetailsDialog
          plugg={state.currentPlugg}
          onCloseCallback={() => dispatch({ type: 'close_plugg_details' })}
        />
      )}
    </>
  );
}

function PluggItem({ plugg, onClick }) {
  const responsiveTypography = useResponsiveTypography();

  return (
    <Pane
      display="flex"
      alignItems="center"
      columnGap="1.25em"
      paddingX="1.25em"
      paddingY="1em"
      borderRadius="0.5em"
      fontSize={responsiveTypography.getFontSize(1, 1.25)}
      backgroundColor="var(--grey-ultra-light)"
      cursor="pointer"
      onClick={onClick}
    >
      <PluggAvatarIcon style={{ flexShrink: 0 }} />
      <Text flexGrow={1} fontSize="inherit">
        {plugg.name}
      </Text>
      <Text
        flexBasis={360}
        flexGrow={1}
        fontSize="inherit"
        textAlign="right"
        opacity="0.4"
      >
        {plugg.services[0].serviceName}
      </Text>
      <Text flexBasis={210} fontSize="inherit" textAlign="right">
        {[plugg.location.area, plugg.location.state].join(', ')}
      </Text>
      <Tooltip
        position={Position.RIGHT}
        statelessProps={{
          maxWidth: '190px',
          color: '#fff',
          fontSize: '0.75rem',
          backgroundColor: '#000',
        }}
        content={
          plugg.services[0].verified
            ? 'This plug has been verified.'
            : 'This plug has not yet been verified.'
        }
      >
        <PluggRatingIcon verified={plugg.services[0].verified.toString()} />
      </Tooltip>
    </Pane>
  );
}

export default PluggSearchResult;
