import { useMediaQuery } from 'react-responsive';
import { vp_bp_factor } from '../enums';

function useViewportBreakpoint(): {
  isSm: boolean,
  isMd: boolean,
  isLg: boolean,
  isXl: boolean,
  isXxl: boolean,
} {
  const isSm = useMediaQuery({ query: `(min-width: ${vp_bp_factor.sm}rem)` });
  const isMd = useMediaQuery({ query: `(min-width: ${vp_bp_factor.md}rem)` });
  const isLg = useMediaQuery({ query: `(min-width: ${vp_bp_factor.lg}rem)` });
  const isXl = useMediaQuery({ query: `(min-width: ${vp_bp_factor.xl}rem)` });
  const isXxl = useMediaQuery({ query: `(min-width: ${vp_bp_factor.xxl}rem)` });

  return { isSm, isMd, isLg, isXl, isXxl };
}

export default useViewportBreakpoint;
