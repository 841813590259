import mixpanel from 'mixpanel-browser';
import {MIXPANEL_TOKEN} from './config';

let token = process.env.REACT_APP_MIXPANEL_TOKEN

if (!token) {
    token = MIXPANEL_TOKEN
}

mixpanel.init(token);

const actions = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    alias: (id) => {
        mixpanel.alias(id);
    },
    track: (name, props) => {
        mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            mixpanel.people.set(props);
        },
    },
};

export default actions;
