import styled from '@emotion/styled';
import { ReactComponent as PluggRating } from '../../assets/icons/plugg-rating.svg';

const PluggRatingIcon = styled(PluggRating)`
  flex-shrink: 0;

  path[fill] {
    fill: ${(props) =>
      props.verified === 'true' ? '#000' : 'var(--grey-medium)'};
  }
`;

export default PluggRatingIcon;
